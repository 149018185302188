<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="16"
    viewBox="0 0 12 16"
  >
    <path
      id="Path_4"
      data-name="Path 4"
      d="M108.3,1140.25l8.058-8-12,8,12,8Z"
      transform="translate(-104.362 -1132.25)"
      :fill="fillColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'PrevArrow',
  props: {
    fillColor: {
      type: String,
      default: '#47040f',
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
