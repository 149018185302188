<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="12.143" viewBox="0 0 10 12.143">
    <path
      id="Icon_metro-file-download"
      data-name="Icon metro-file-download"
      d="M19,10.712H16.14V6.427H11.855v4.286H9l5,5ZM9,17.141V18.57H19V17.141H9Z"
      transform="translate(-8.997 -6.427)" :fill="fillColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'Download',
  props: {
    fillColor: {
      type: String,
      default: '#fff',
    },
  },
};
</script>
