var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"color_picker"},[(_vm.isArrowShow)?_c('PrevArrow',{staticClass:"color_picker_btn color_picker_btn-prev",nativeOn:{"click":function($event){$event.stopPropagation();return _vm.clickToMove(-1)}}}):_vm._e(),_c('div',{staticClass:"color_picker_wrapper",class:[
      _vm.overflow ? 'overflow_wrapper noScrollBar' : '' ]},[_c('div',{ref:"moveTarget",staticClass:"color_container"},_vm._l((_vm.colors),function(color,idx){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(color.value),expression:"color.value"}],key:("color_" + idx),staticClass:"color_box txt-left",class:{
          active: _vm.activeIdx === idx,
          'border-gray': color.value === '#fff'
            || color.value === '#ffffff'
            || color.value === '#FFF'
            || color.value === '#FFFFFF',
        },style:({ backgroundColor: color.value }),on:{"click":function($event){$event.stopPropagation();return _vm.chosenColorIdx(idx)}}})}),0)]),(_vm.isArrowShow)?_c('NextArrow',{staticClass:"color_picker_btn color_picker_btn-next",nativeOn:{"click":function($event){$event.stopPropagation();return _vm.clickToMove(1)}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }