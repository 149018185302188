<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="30.707" height="30.202" viewBox="0 0 30.707 30.202">
    <g id="Group_572" data-name="Group 572" transform="translate(-313 -473)">
      <g id="Group_571" data-name="Group 571" transform="translate(313 473)">
        <g id="Group_508" data-name="Group 508">
          <g id="Ellipse_9" data-name="Ellipse 9" fill="none" stroke="#47040f" stroke-width="2">
            <circle cx="12.787" cy="12.787" r="12.787" stroke="none"/>
            <circle cx="12.787" cy="12.787" r="11.787" fill="none"/>
          </g>
          <path
            id="Path_27" data-name="Path 27" d="M-1055.639-1239.955l8.644,8.644"
            transform="translate(1076.995 1260.806)" fill="none" stroke="#47040f" stroke-width="2"
          />
        </g>
      </g>
      <path
        id="Path_306" data-name="Path 306" d="M3434.681-857.156h13"
        transform="translate(-3115.181 1342.906)" fill="none" stroke="#47040f" stroke-width="2"
      />
      <path
        id="Path_307" data-name="Path 307" d="M0,0H13"
        transform="translate(326 479.25) rotate(90)" fill="none" stroke="#47040f" stroke-width="2"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'ZoomIn',
};
</script>
