<template>
  <a
    href="./file/catalogue.pdf"
    target="_blank"
    class="pdf_download"
    @mouseenter="isHovered = true"
    @mouseleave="isHovered = false"
  >
    <span class="p">全產品型錄下載</span>
    <Download :fillColor="isHovered ? '#D30025' : '#fff'" />
  </a>
</template>

<script>
import Download from '@/components/icon/Download.vue';

export default {
  name: 'PdfDownloader',
  components: {
    Download,
  },
  data() {
    return {
      isHovered: false,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/helper/_variable.scss';
.pdf_download {
  position: absolute;
  right: 0;
  bottom: 5vh;
  color: #fff;
  background-color: $color-red;
  width: 60px;
  padding: 16px;
  text-align: center;
  z-index: 1;
  @media screen and (min-width: 1024px) {
    &:hover {
      background-color: $color-main;
    }
  }
  span {
    display: inline-block;
    padding-bottom: 5px;
    line-height: 1.3rem;
  }
  &.fixed {
    position: fixed;
    right: 0;
    bottom: 5vh;
  }
  @media screen and (max-width: 480px) {
    width: 50px;
    padding: 10px;
  }
}
</style>
