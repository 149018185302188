<template>
  <div id="pagination">
    <!-- 前往第一頁 -->
    <button
      class="prev"
      v-show="page > 1"
      @click="startPage"
      @mouseenter="isPrevStartHovered = true"
      @mouseleave="isPrevStartHovered = false"
    >
      <PrevArrow :fillColor="isPrevStartHovered ? hoveredColor : originalColor" />
      <PrevArrow :fillColor="isPrevStartHovered ? hoveredColor : originalColor" />
    </button>

    <!-- 前往上一頁 -->
    <button
      class="prev"
      v-show="page > 1"
      @click="changePage(-1)"
      @mouseenter="isPrevHovered = true"
      @mouseleave="isPrevHovered = false"
    >
      <PrevArrow :fillColor="isPrevHovered ? hoveredColor : originalColor" />
    </button>

    <!-- 頁碼區 -->
    <ul>
      <li
        v-for="pageNum in pageList"
        :key="pageNum"
        :class="{ active: pageNum === page }"
      >
        <button @click="jumpPage(pageNum)">{{ pageNum }}</button>
      </li>
    </ul>

    <!-- 前往下一頁 -->
    <button
      class="next"
      v-show="page < totalPage"
      @click="changePage(1)"
      @mouseenter="isNextHovered = true"
      @mouseleave="isNextHovered = false"
    >
      <NextArrow :fillColor="isNextHovered ? hoveredColor : originalColor" />
    </button>

    <!-- 前往最末頁 -->
    <button
      class="next"
      v-show="page < totalPage"
      @click="endPage"
      @mouseenter="isNextEndHovered = true"
      @mouseleave="isNextEndHovered = false"
    >
      <NextArrow :fillColor="isNextEndHovered ? hoveredColor : originalColor" />
      <NextArrow :fillColor="isNextEndHovered ? hoveredColor : originalColor" />
    </button>
  </div>
</template>

<script>
import PrevArrow from '@/components/icon/PrevArrow.vue';
import NextArrow from '@/components/icon/NextArrow.vue';

export default {
  name: 'Pagination',
  props: {
    // pageData: Array,
    // perPage: Number,
    pageButton: { // 有多頁時，一次最多顯示幾個頁碼
      type: Number,
      default: 5,
    },
    page: {
      type: [Number, String],
      default: 1,
    },
    totalPage: {
      type: Number,
      default: 1,
    },
  },
  components: {
    PrevArrow,
    NextArrow,
  },
  data() {
    return {
      originalColor: '#C4C4C4',
      hoveredColor: '#47040F',
      isPrevStartHovered: false,
      isPrevHovered: false,
      isNextHovered: false,
      isNextEndHovered: false,
    };
  },
  computed: {
    pageList() {
      /**
       * Page Buttons Per Time: [page-2, page-1, page, page+1, page+2]
       */
      const pageListArray = [];
      for (let i = 1; i < this.totalPage + 1; i += 1) {
        pageListArray.push(i);
      }
      if (this.pageButton >= this.totalPage) {
        return pageListArray;
      }
      const indexNow = this.page - 1;
      switch (true) {
        case this.page === 1:
          return pageListArray.slice(indexNow, indexNow + this.pageButton);
        case this.page === 2:
          return pageListArray.slice(indexNow - 1, indexNow + this.pageButton - 1);
        case this.page + 1 > this.totalPage:
          return pageListArray.slice(indexNow - this.pageButton + 1);
        case this.page + 2 > this.totalPage:
          return pageListArray.slice(indexNow - this.pageButton + 2);
        case this.page + 3 > this.totalPage:
          return pageListArray.slice(indexNow - 2);
        default:
          return pageListArray.slice(indexNow - Math.floor(this.pageButton / 2), indexNow
          + Math.ceil(this.pageButton / 2));
      }
    },
  },
  methods: {
    changePage(direct) {
      const page = this.page + direct;
      if (page < 1) {
        this.$emit('updatePage', 1);
        this.$router.push({ query: { num: 1 } });
      } else if (page > this.totalPage) {
        this.$emit('updatePage', this.totalPage);
      } else {
        this.$emit('updatePage', page);
      }
    },
    jumpPage(destination) {
      this.$emit('updatePage', destination);
    },
    startPage() {
      this.$emit('updatePage', 1);
    },
    endPage() {
      this.$emit('updatePage', this.totalPage);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/helper/_variable.scss';
#pagination {
  margin: 10% 0;
  display: flex;
  justify-content: center;
  align-items: center;
  > button {
    margin: 0 5px;
    padding: 0 5px;
    width: 34px;
    height: 34px;
    svg {
      vertical-align: middle;
    }
    &.prev {
      transition: 0.2s;
      // img, svg {
      //   transform: rotate(180deg);
      // }
      @media screen and (min-width: 1024px){
        &:hover {
          transform: translateX(-5px);
        }
      }
    }
    &.next {
      transition: 0.2s;
      @media screen and (min-width: 1024px){
        &:hover {
          transform: translateX(5px);
        }
      }
    }
  }
  ul {
    display: flex;
    justify-content: center;
    align-items: center;
    li {
      margin: 0 5px;
      font-weight: 500;
      button {
        position: relative;
        width: 20px;
        color: $color-medium_gray;
        &::before {
          content: "";
          position: absolute;
          left: 50%;
          bottom: 0;
          transform: translateX(-50%);
          width: 18px;
          height: 2px;
          background-color: $color-red;
          opacity: 0;
        }
      }
      @media screen and (min-width: 1024px){
        &:hover {
          button {
            color: $color-main;
          }
        }
      }
      &.active {
        button {
          color: $color-main;
          &::before {
            opacity: 1;
          }
        }
      }
    }
  }
}
</style>
