<template>
  <div
    class="product_item"
    @click="showProductModal"
  >
    <div class="top">
      <div class="product_item_pic">
        <div
          class="product_item_pic_render"
          :style="{ backgroundImage: `url('${activePic}')` }"
        ></div>
      </div>
      <div class="product_item_name large txt-center">
        {{ convertToEnglish(product.name, product.name_en) }}
      </div>
      <div class="product_item_no small txt-center txt-red">{{ product.no }}</div>
    </div>
    <div class="bottom">
      <div class="bottom_info small txt-center txt-dark_gray-1">
        <!-- 尺寸 -->
        <template v-if="product.size">
          {{ product.size }}
        </template>

        <!-- 同時有尺寸及顏色時，顯示逗號 -->
        <template v-if="product.size && colorList[activeName]">, </template>

        <!-- 顏色 -->
        <template v-if="colorList[activeName]">
          {{ convertToEnglish(colorList[activeName].name, colorList[activeName].name_en) }}
        </template>
      </div>
      <ColorPicker
        :colors="product.colors"
        :overflow="true"
        @updateColorActiveIdx="updateColorActiveIdx"
      />
    </div>
    <div
      v-if="product.blade_pic"
      class="blade_pic_wrapper"
    >
      <div
        class="blade_pic"
        :style="{ backgroundImage: `url('${product.blade_pic}')` }"
      ></div>
    </div>
  </div>
</template>

<script>
import { colorList } from '@/lib/const';
import ColorPicker from '@/components/colorPicker/Index.vue';
import ModalProduct from '@/views/products/ModalProduct.vue';
import languageMixin from '@/mixins/languageMixin';

export default {
  name: 'ProductItem',
  mixins: [languageMixin],
  props: {
    series: {
      type: Object,
      default() {
        return {};
      },
    },
    product: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  components: {
    ColorPicker,
  },
  data() {
    return {
      colorList,
      activeIdx: 0,
    };
  },
  computed: {
    activeColor() {
      return this.product.colors?.[this.activeIdx] || {};
    },
    activePic() {
      return this.activeColor?.pic || '';
    },
    activeName() {
      return this.activeColor?.name || '';
    },
  },
  methods: {
    updateColorActiveIdx(idx) {
      this.activeIdx = idx;
    },
    showProductModal() {
      this.$store.commit('site/SET_MODAL', {
        show: true,
        position: '', // top or bottom
        size: 'md',
        backgroundColor: '#fff',
        contentComponent: ModalProduct,
        propsData: {
          series: this.series,
          product: this.product,
          initActiveIdx: this.activeIdx,
        },
        confirmCallback: null,
        cancelCallback: null,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/helper/_variable.scss';
.product_item {
  position: relative;
  margin-bottom: .5rem;
  padding: 4%;
  padding-top: 6%;
  border: 1px solid $color-light_gray;
  cursor: pointer;
  transition: box-shadow 0.1s linear;
  .top {
    position: relative;
    z-index: 0;
  }
  .bottom {
    margin-top: 1rem;
    border-top: 1px solid $color-light_gray;
    .bottom_info {
      padding: 0.5rem 0;
    }
  }
  .product_item_pic {
    margin-bottom: 2rem;
    transition: transform 0.1s linear;
    &_render {
      width: 100%;
      padding-bottom: 30%;
      background: center/contain no-repeat;
    }
  }
  .blade_pic_wrapper {
    position: absolute;
    top: 3%;
    right: 3%;
    width: 15%;
    z-index: 1;
    .blade_pic {
      width: 100%;
      padding-bottom: 100%;
      background: center/contain no-repeat;
    }
  }
  @media screen and (min-width: 1024px) {
    &:hover {
      box-shadow: 2px 2px 20px rgba(#000, 0.1);
      .product_item_pic {
        transform: scale(1.1);
      }
    }
  }
  @media screen and (max-width: 480px) {
    padding-top: 20%;
  }
}
</style>
