<template>
  <div class="color_picker">
    <PrevArrow
      v-if="isArrowShow"
      class="color_picker_btn color_picker_btn-prev"
      @click.native.stop="clickToMove(-1)"
    />
    <div
      class="color_picker_wrapper"
      :class="[
        overflow ? 'overflow_wrapper noScrollBar' : '',
      ]"
    >
      <div ref="moveTarget" class="color_container">
        <div
          v-for="(color, idx) in colors"
          :key="`color_${idx}`"
          v-show="color.value"
          class="color_box txt-left"
          :class="{
            active: activeIdx === idx,
            'border-gray': color.value === '#fff'
              || color.value === '#ffffff'
              || color.value === '#FFF'
              || color.value === '#FFFFFF',
          }"
          :style="{ backgroundColor: color.value }"
          @click.stop="chosenColorIdx(idx)"
        ></div>
      </div>
    </div>
    <NextArrow
      v-if="isArrowShow"
      class="color_picker_btn color_picker_btn-next"
      @click.native.stop="clickToMove(1)"
    />
  </div>
</template>

<script>
import PrevArrow from '@/components/icon/PrevArrow.vue';
import NextArrow from '@/components/icon/NextArrow.vue';

export default {
  name: 'ColorPicker',
  props: {
    colors: {
      type: Array,
      default() {
        return [];
      },
    },
    overflow: {
      type: Boolean,
      default: false,
    },
    initActiveIdx: {
      type: Number,
      default: 0,
    },
  },
  components: {
    PrevArrow,
    NextArrow,
  },
  data() {
    return {
      moveCount: 0,
      activeIdx: 0,
    };
  },
  computed: {
    isArrowShow() {
      return this.overflow && this.colors.length >= 5;
    },
  },
  methods: {
    moveCalculator() {
      const moveUnit = 26;
      const target = this.$refs.moveTarget;
      target.style.right = `${moveUnit * this.moveCount}px`;
    },
    clickToMove(dir) {
      const showItems = 5;
      if (dir > 0 && this.moveCount + 1 <= this.colors.length - showItems) {
        this.moveCount += 1;
      } else if (dir < 0 && this.moveCount - 1 >= 0) {
        this.moveCount -= 1;
      } else {
        return;
      }
      this.moveCalculator();
    },
    chosenColorIdx(idx) {
      this.activeIdx = idx;
      this.$emit('updateColorActiveIdx', idx);
    },
  },
  mounted() {
    if (this.initActiveIdx) this.activeIdx = this.initActiveIdx;
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/helper/_variable.scss';
.color_picker {
  position: relative;
  .color_picker_wrapper {
    margin: 0 auto;
    white-space: nowrap;
    .color_box {
      position: relative;
      display: inline-block;
      margin: 0 4px;
      width: 18px;
      height: 18px;
      background: #000;
      cursor: pointer;
      border: 1px solid transparent;
      &.border-gray {
        border: 1px solid $color-gray; // 只有白色會有灰框
      }
      &.active {
        &::before {
          content: '';
          width: 100%;
          height: 2px;
          background-color: $color-red;
          position: absolute;
          bottom: -8px;
        }
      }
    }
  }
  .color_picker_btn {
    padding: 5px;
    box-sizing: content-box;
    position: absolute;
    top: -2px;
    cursor: pointer;
    &-prev {
      left: 0;
      transform: translateX(-100%);
    }
    &-next {
      right: 0;
      transform: translateX(100%);
    }
  }
  .color_container {
    position: relative;
    right: 0;
    text-align: center;
  }
}
.overflow_wrapper {
    overflow: auto;
    max-width: calc(26px * 5);
  }
</style>
